































import { Component, Prop, Vue } from "vue-property-decorator";
import { Coupon } from "@/api/mine.api";
import { countdown, getNow, observer, unObserver } from "@/utils/common.util";

@Component({})
export default class DiscountItem extends Vue {
  @Prop() private readonly item!: Coupon;
  countdownText = "0天00:00:00";

  handleOpenPage(): void {
    const { type, target } = this.item;
    const name = type === 0 ? "StoreDetail" : "BlindBoxDetail";
    this.$router.push({
      name,
      params: { id: String(target) },
      query: { time: String(getNow()) },
    });
  }

  countdownNotice(): void {
    const { day, hour, minute, second } = countdown(
      this.item.failure_time || 0
    );
    this.countdownText = `${day}天${hour}:${minute}:${second}`;
  }

  mounted(): void {
    observer("countdownNotice", this.countdownNotice.bind(this));
  }

  destroyed(): void {
    unObserver("countdownNotice", this.countdownNotice.bind(this));
  }
}
