














import { Component, Vue } from "vue-property-decorator";
import DiscountItem from "@/views/mine/components/DiscountItem.vue";
import { List } from "vant";
import { Coupon, MineApi } from "@/api/mine.api";
import EmptyBox from "@/components/EmptyBox.vue";
import { observer } from "@/utils/common.util";

@Component({
  components: { EmptyBox, DiscountItem, List },
})
export default class MyDiscount extends Vue {
  coupons: Coupon[] = [];
  page = 0;
  page_size = 10;
  loading = false;
  finished = false;

  mounted() {
    observer("refreshInit", () => {
      this.page = 0;
      this.loading = false;
      this.finished = false;
      this.loadData();
    });
  }

  async loadData(): Promise<void> {
    this.page++;
    const { page, page_size, coupons } = this;
    const rows = await MineApi.getCoupon(page, page_size).catch(() => []);
    this.loading = false;
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.coupons = page > 1 ? [...coupons, ...rows] : rows;
  }
}
